@import '../typography/typography';

/**
 * Styles for form fields, hard-coded typographies so far. Refactor once
 * customization is needed.
 */

$icon-width: 48px;
$field-height: 60px;

$input: typography-level(18px, 2.3, 400, null, 0.35px);
$subscript: typography-level(14px, 30px, 400, null, 0.35px);
$typography: typography-config(
  $input: $input,
  $label: $subscript
);

.a7-form-field.mat-form-field, .a7-form-field.mat-mdc-form-field {

  .mat-form-field-wrapper, .mat-mdc-form-field-wrapper {

    .mat-form-field-flex, .mat-mdc-form-field-flex {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center space-between;
      align-items: center;

      .mat-form-field-prefix, .mat-mdc-form-field-icon-prefix {
        width: $icon-width;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        max-height: 100%;
        place-content: center space-around;
        align-items: center;

        .form-icon {
          font-size: 24px;
          margin: 0 auto;
          line-height: $field-height;
        }
      }

      .mat-form-field-infix, .mat-mdc-form-field-infix {
        @include typography-level-to-styles($typography, input);

        padding: 0;
        border-top-width: 0;

        .mat-form-field-label-wrapper, .mat-mdc-form-field-label-wrapper {
          top: 0;
          padding-top: 0;
          .mat-form-field-label, .mat-mdc-form-field-label {
            transform: none;
            top: 0;
            @include typography-level-to-styles($typography, input);
          }
        }
      }
    }

    .mat-form-field-underline {
      background: #CBD2D6;
    }

    .mat-form-field-subscript-wrapper {
      @include typography-level-to-styles($typography, label);
      padding-left: $icon-width;
      top: $field-height;
      margin-top: 0;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    @include typography-level-to-styles($typography, label);
    padding-left: $icon-width;
    top: 0;
    margin-top: 0;
  }

  &.mat-focused {
    .mat-form-field-ripple, .mat-mdc-form-field-ripple {
      transform: scaleX(1.1);
    }

    .mat-form-field-prefix, .mat-mdc-form-field-prefix {
      transform: scale(1.4);
      transition: all 0.2s cubic-bezier(.55,0,.55,.2);
    }
  }
}
