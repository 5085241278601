@mixin a7-ui-auth($ds: null) {
  @include with-ds($ds) {
    .a7-ui-auth-form {
      min-height: 100%;
      max-width: 100%;
      max-width: 460px;
      display: block;
      margin: 0 auto;
      flex: 1 0 auto;
      box-sizing: border-box;

      .mat-tab-body.mat-tab-body-active {
        overflow: visible;
        max-height: 100%;
      }
      .mat-tab-body-content {
        overflow: visible;
        max-height: 100%;
      }

      .a7-ui-auth-form_tab-group {
        min-height: 100%;
        margin: 0 -36px;
        flex: 1 0 auto;
        max-width: unset;

        > .mat-tab-header, > .mat-mdc-tab-header {
          display: none;
        }
        > .mat-tab-body-wrapper, > .mat-mdc-tab-body-wrapper {
          min-height: 100%;
          flex: 1 0 auto;

          > .mat-tab-body, > .mat-mdc-tab-body {
            min-height: 100%;
            > .mat-tab-body-content, > .mat-mdc-tab-body-content {
              padding: 0 36px;
            }
          }
        }
      }
    }

    h2.a7-ui-auth-form-title {
      text-align: center;
      font-size: 24px !important;
      line-height: 48px;
      font-weight: 500;
      letter-spacing: 0.74px;
      margin-top: 24px;
      margin-bottom: 60px;
    }

    .a7-ui-auth-form-content {
      box-sizing: border-box;
      width: 320px;
      max-width: 100%;
    }

    .a7-ui-auth-form_section {
      margin-bottom: 48px;
      max-width: 320px;

      .mat-form-field, .mat-mdc-form-field {
        width: 100%;
        background: white;
      }

      &.info-section {
        text-align: center;
      }

      &.action-section {
        flex-flow: row wrap;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
        text-align: center;

        > * {
          width: 100%;
        }

        > *:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      &.bottom-section {
        text-align: center;
      }
    }
  }

  @include with-ds($ds, '.page-SM, .page-XS') {
    .a7-ui-auth-form,
    .a7-ui-auth-form_tab-group .mat-tab-body-content {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: stretch space-evenly;
      align-items: stretch;
      flex: 1 0 auto;
    }

    .a7-ui-auth-form_info-tab {
      min-height: 100%;
    }
  }
}

@include exports('a7-ui-auth') {
  @include a7-ui-auth();
}

@mixin a7-ui-auth-theme($theme, $ds: null) {
  $primary: map-get($theme, primary);
  $primary-color: map-get($primary, default);

  @include with-ds($ds) {
    .a7-ui-auth-form h2.a7-ui-auth-form-title {
      color: $primary-color;
    }

    .a7-ui-auth-form_section {
      .form-icon {
        color: $primary-color;
      }
      .info-section {
        color: $a7-color-black-medium-high-emphasis;
      }
    }
  }
}

@mixin a7-ui-auth-typography($config, $ds: null) {
}
