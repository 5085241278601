@import './layout-utils';

// Reference: https://material.io/design/layout/responsive-layout-grid.html

$dirs: (
t: top,
b: bottom,
l: left,
r: right,
);
$opers: margin, padding;

$total-range: (4, 8, 12);
$column-range: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

%separator {
  border-style: solid;
  border-width: 0;
}

@mixin a7-column($total) {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: stretch;
  @include spacing(margin-right, -1);

  &.a7-first-row-adjust {
    @include spacing(margin-right, -1);

    .a7-first-row-col {
      @include spacing(margin-right, 1);
    }
  }

  @each $column in $column-range {

    .a7-col-#{$column} {
      @include column($total, $column);
    }
  }
}

@mixin a7-condition-column($total) {
  @each $column in $column-range {

    @if $column <= $total {
      .a7-col-#{$column}-of-#{$total} {
        @include column($total, $column);
      }
    }
  }

}

@mixin a7-ui-layout() {

  .layout-sc-1140 {
    max-width: 1140px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .layout-sc-1324 {
    max-width: 1324px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .layout-sc-952 {
    max-width: 952px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .layout-w-293 {
    width: 293px;
  }

  .layout-col-top {
    display: table;
    table-layout: fixed;
    width: 100%;

    > * {
      display: table-cell;
      vertical-align: top;
    }
  }

  // Spacing
  @for $scale from 0 through 30 {
    $mul: $scale / 3;

    @each $name, $key in $dirs {
      .#{$key}-#{$scale} {
        @include spacing($key, $mul);
      }
    }

    @each $oper in $opers {
      .#{$oper}-#{$scale} {
        @include spacing($oper, $mul);
      }
      .#{$oper}-#{$scale}-n {
        @include spacing($oper, -$mul);
      }
      .#{$oper}-#{$scale}-h {
        @include spacing(#{$oper}-left, $mul);
        @include spacing(#{$oper}-right, $mul);
      }
      .#{$oper}-#{$scale}-h-n {
        @include spacing(#{$oper}-left, -$mul);
        @include spacing(#{$oper}-right, -$mul);
      }
      .#{$oper}-#{$scale}-v {
        @include spacing(#{$oper}-top, $mul);
        @include spacing(#{$oper}-bottom, $mul);
      }
      .#{$oper}-#{$scale}-v-n {
        @include spacing(#{$oper}-top, -$mul);
        @include spacing(#{$oper}-bottom, -$mul);
      }

      @each $d, $dir in $dirs {
        .#{$oper}-#{$scale}-#{$d} {
          @include spacing(#{$oper}-#{$dir}, $mul);
        }
        .#{$oper}-#{$scale}-#{$d}-n {
          @include spacing(#{$oper}-#{$dir}, -$mul);
        }
      }
    }
  }

  // Page Margin
  .a7-page-margin {
    @include spacing(padding-left, 1);
    @include spacing(padding-right, 1);
  }

  // Columns
  @each $total in $total-range {

    .a7-cols-#{$total} {
      @include a7-column($total);
    }
  }

  .a7-cols {

    @media only screen and (min-width: 0) and (max-width: 599px) {
      @include a7-column(4);
      @include a7-condition-column(4);
    }
    @media only screen and (min-width: 600px) and (max-width: 839px) {
      @include a7-column(8);
      @include a7-condition-column(8);
    }
    @media only screen and (min-width: 840px) {
      @include a7-column(12);
      @include a7-condition-column(12);
    }
  }

  .a7-min-full-vh {
    @include minVH();
  }

  .a7-full-vh {
    @include VH();
  }

  // Separators
  $sizes: (
  1: 1px,
  2: 2px,
  );

  @each $scale, $size in $sizes {

    .separator-#{$scale} {
      @extend %separator;
      border-bottom-width: $size;
    }

    @each $d, $dir in $dirs {
      .separator-#{$scale}-#{$d} {
        @extend %separator;
        border-#{$dir}-width: $size;
      }
    }
  }

  // Borders
  @each $scale, $size in $sizes {

    .border-#{$scale} {
      border-width: $size;
      border-style: solid;
    }

    .border-#{$scale}-h {
      border-left-width:   $size;
      border-right-width:  $size;
      border-style: solid;
    }

    .border-#{$scale}-v {
      border-top-width:     $size;
      border-bottom-width:  $size;
      border-style: solid;
    }

    @each $d, $dir in $dirs {
      .border-#{$scale}-#{$d} {
        border-width: 0px;
        border-#{$dir}-width: $size;
        border-style: solid;
      }
    }
  }
}
