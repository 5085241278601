@font-face {
  font-family: 'icomoon';
  src: url('/icons/icomoon.eot?hsqa7k');
  src: url('/icons/icomoon.eot?hsqa7k#iefix') format('embedded-opentype'),
    url('/icons/icomoon.ttf?hsqa7k') format('truetype'),
    url('/icons/icomoon.woff?hsqa7k') format('woff'),
    url('/icons/icomoon.svg?hsqa7k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chat-3:before {
  content: '\e938';
}
.icon-seed-flower-3:before {
  content: '\e936';
}
.icon-diamond-3:before {
  content: '\e937';
}
.icon-application-weight-3:before {
  content: '\e933';
}
.icon-give-3:before {
  content: '\e932';
}
.icon-increase-3:before {
  content: '\e935';
}
.icon-share-3:before {
  content: '\e934';
}
.icon-gift-300:before {
  content: '\e931';
}
.icon-sold-out-300:before {
  content: '\e930';
}
.icon-regular-user-300:before {
  content: '\e92f';
}
.icon-pdf-weight-2:before {
  content: '\e92e';
}
.icon-relation-weight-3:before {
  content: '\e92d';
}
.icon-upload-3:before {
  content: '\e92b';
}
.icon-view-dashboard-3:before {
  content: '\e92c';
}
.icon-income-200:before {
  content: '\e927';
}
.icon-total-200:before {
  content: '\e928';
}
.icon-price-tag-200:before {
  content: '\e929';
}
.icon-tags-200:before {
  content: '\e92a';
}
.icon-home-solid-weight-3:before {
  content: '\e900';
}
.icon-home-weight-3:before {
  content: '\e926';
}
.icon-renter-logo-weight-5:before {
  content: '\e925';
}
.icon-renter-logo-weight-1:before {
  content: '\e921';
}
.icon-renter-logo-weight-2:before {
  content: '\e922';
}
.icon-renter-logo-weight-3:before {
  content: '\e923';
}
.icon-renter-logo-weight-4:before {
  content: '\e924';
}
.icon-caret-up-weight-3:before {
  content: '\e91f';
}
.icon-caret-down-weight-3:before {
  content: '\e91e';
}
.icon-plus-weight-3:before {
  content: '\e91d';
}
.icon-caret-right-weight-3:before {
  content: '\e91c';
}
.icon-caret-left-weight-3:before {
  content: '\e91b';
}
.icon-bars-weight-3:before {
  content: '\e920';
}
.icon-package-weight-3:before {
  content: '\e90e';
}
.icon-credit-card-weight-3:before {
  content: '\e919';
}
.icon-left-arrow-weight-3:before {
  content: '\e918';
}
.icon-ark7-logo-s1:before {
  content: '\e901';
}
.icon-fullsize-logo-weight-1:before {
  content: '\e902';
}
.icon-fullsize-logo-weight-2:before {
  content: '\e903';
}
.icon-fullsize-logo-weight-3:before {
  content: '\e904';
}
.icon-fullsize-logo-weight-4:before {
  content: '\e905';
}
.icon-fullsize-logo-weight-5:before {
  content: '\e906';
}
.icon-square-logo-weight-1:before {
  content: '\e907';
}
.icon-square-logo-weight-2:before {
  content: '\e908';
}
.icon-square-logo-weight-3:before {
  content: '\e909';
}
.icon-square-logo-weight-4:before {
  content: '\e90a';
}
.icon-square-logo-weight-5:before {
  content: '\e90b';
}
.icon-email-weight-3:before {
  content: '\e90c';
}
.icon-lock-weight-3:before {
  content: '\e90d';
}
.icon-user-weight-3:before {
  content: '\e90f';
}
.icon-pie-weight-3:before {
  content: '\e910';
}
.icon-divide-weight-3:before {
  content: '\e911';
}
.icon-edit-weight-3:before {
  content: '\e912';
}
.icon-coins-weight-3:before {
  content: '\e913';
}
.icon-book-case-weight-3:before {
  content: '\e914';
}
.icon-gears-weight-3:before {
  content: '\e915';
}
.icon-sign-out-weight-3:before {
  content: '\e916';
}
.icon-question-weight-3:before {
  content: '\e917';
}
.icon-code-weight-3:before {
  content: '\e91a';
}
