$layout-directions: (row, column, row-reverse, column-reverse);
$alignment-directions: (start, center, end, around, between, evenly);
$perpendicular-directions: (start, center, end, stretch, baseline);

$flex-direction-map: (start: flex-start,
  end: flex-end,
  around: space-around,
  between: space-between,
  evenly: space-evenly,
);

@mixin flex($direction,
  $alignment-direction: start,
  $perpendicular-direction: stretch,
) {
  $alignment-direction: map-get($flex-direction-map, $alignment-direction) or $alignment-direction;
  $perpendicular-direction: map-get($flex-direction-map, $perpendicular-direction) or $perpendicular-direction;

  flex-direction: $direction;
  box-sizing: border-box;
  display: flex;
  place-content: $perpendicular-direction $alignment-direction;
  align-items: $perpendicular-direction;
}

@mixin flex-row($alignment-direction: start,
  $perpendicular-direction: stretch,
) {
  @include flex(row, $alignment-direction, $perpendicular-direction);
}

@mixin flex-column($alignment-direction: start,
  $perpendicular-direction: stretch,
) {
  @include flex(column, $alignment-direction, $perpendicular-direction);
}

@mixin flex-row-wrap($alignment-direction: start,
  $perpendicular-direction: stretch,
) {
  @include flex-row($alignment-direction, $perpendicular-direction);
  flex-wrap: wrap;
}

@mixin flex-column-wrap($alignment-direction: start,
  $perpendicular-direction: stretch,
) {
  @include flex-column($alignment-direction, $perpendicular-direction, wrap);
  flex-wrap: wrap;
}

@each $layout-direction in $layout-directions {
  .flex-#{$layout-direction} {
    @include flex($layout-direction);
  }

  @each $alignment-direction in $alignment-directions {
    .flex-#{$layout-direction}-#{$alignment-direction} {
      @include flex($layout-direction, $alignment-direction);
    }

    @each $perpendicular-direction in $perpendicular-directions {
      .flex-#{$layout-direction}-#{$alignment-direction}-#{$perpendicular-direction} {
        @include flex($layout-direction,
          $alignment-direction,
          $perpendicular-direction,
        );
      }
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row-wrap {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
}

.flex-row-wrap-stretch {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
}

.flex-row-wrap-center {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}

.flex-row-wrap-center-start {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: flex-start;
}

.flex-row-wrap-baseline {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: baseline;
}


.flex-scale {
  flex: 1 1 auto;
}

.flex-factor-1 {
  flex: 1;
}

.flex-justify-content-between {
  justify-content: space-between
}

.flex-scale-basis-0 {
  // Note that flex-basis is strictly contend width, excluding paddings.
  // Css reset to border-box does not change this.
  // https://www.youtube.com/watch?v=fm3dSg4cxRI
  flex: 1 1 0;
}

.flex-scale-up {
  flex: 1 0 0%;
}

.flex-scale-down {
  flex: 0 1 100%;
}

.flex-noshrink {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

@mixin flex-gap($gap) {
  &:not([class*="flex-column"]) {
    margin-right: -$gap;
    margin-bottom: -$gap;
    width: calc(100% + #{$gap});

    >* {
      margin-right: $gap;
      margin-bottom: $gap;
    }
  }

  &[class*="flex-column"] {
    >*:not(:last-child) {
      margin-bottom: $gap;
    }
  }
}

$gaps: (4, 12, 24, 36, 48, 56, 84);

@each $gap in $gaps {
  .flex-gap-#{$gap} {
    @include flex-gap(#{$gap}px);
  }
}
