@mixin a7-ui-panel() {
  .a7-switch-panel-group {
    display: block;
    position: relative;
    overflow: hidden;
  }
}

@include exports('a7-panel') {
  @include a7-ui-panel();
}

@mixin a7-ui-panel-theme($theme) {
  $primary:    map-get($theme, primary);
  $primary-color: map-get($primary, default);

  .a7-panel {
    .a7-panel-header {
      .mat-tab-group {
        .mat-tab-header {
          .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
            color: $primary-color;
          }

          .mat-tab-label:focus:not(.mat-tab-disabled):not(.mat-tab-label-active) {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

@mixin a7-ui-panel-typography($config) {
  .a7-panel {
    .a7-panel-header {
      .mat-tab-group {
        .mat-tab-header {
          .mat-tab-label .mat-tab-label-content {
            @include typography-level-to-styles($config, label);
            line-height: inherit;
          }
        }
      }
    }
  }
}
