@import './projects/a7/ui-scss/assets/setup';
@import './projects/a7/ui-scss/assets/statics';
@import './projects/a7/ui-scss/assets/theming';
@import './projects/a7/ui-scss/assets/delight';
@import './projects/a7/panel/assets/panel';

@import './projects/a7/ui-auth/assets/ui-auth';
@import './projects/a7/ui-delight-form-layout/assets/ui-delight-form-layout';
@import './projects/a7/ui-scss/assets/refinement';
@import './projects/a7/ui-scss/assets/a2';
@import '../../../node_modules/ngx-sharebuttons/themes/circles';

.ark7-resource {
  @include a7-ui-typography($refinement-desktop-typography);
}

body {
  @include a7-ui-base-theme($refinement-theme);
}

@include a7-ui-auth-theme($delight-theme);
@include a7-ui-delight-form-layout-theme($delight-theme);
@include a7-ui-delight-form-layout-typography($delight-desktop-typography);

@import './scss/common';
// @import './scss/lato';
@import './scss/variables';

html,
body {
  font-family: Heebo, 'Helvetica Neue', sans-serif;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #171718;
}

.site-container {
  max-width: calc(100% - 72px);
  margin: 0 auto;
  padding: 0 36px;
}

@media only screen and (min-width: 1024px) {
  .site-container {
    width: calc(100% - 72px);
    max-width: $desktop-site-width;
    padding: 0 36px;
  }
}

@media only screen and (max-width: 768px) {
  .site-container {
    width: calc(100% - 72px);
  }
}

.button,
.flat-button,
.stroke-button,
.round-button {
  padding: 0 18px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 38px;
  display: inline-block;
  font-weight: 600;
}

.flat-button {
  background: #aaa;
  font-weight: 700;
  letter-spacing: 0.04em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  &[color='primary'] {
    background: $primary-color;
    color: $primary-contrast;
    text-decoration: none;
  }

  &.color-inverse[color='primary'] {
    color: $primary-color;
    background: $primary-contrast;
  }
}

.round-button {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.86px;
  border-radius: 8px;
  padding: 12px 20px;

  &[color='primary'] {
    background: $primary-color;
    color: $primary-contrast !important;
    text-decoration: none;
  }

  &.color-inverse[color='primary'] {
    color: $primary-color !important;
    background: $primary-contrast;
  }
}

.stroke-button {
  border: 1px solid #171718;
  box-sizing: border-box;
  font-weight: 600;
}

.background-light-gray {
  background: rgba(230, 230, 230, 0.35);
}

.background-primary {
  background: $primary-color;
}

.background-black-high {
  background: #000;
}

.background-dark-green {
  background: #214f4b;
}

.black-high {
  color: #000;
}

.white-high {
  color: #fff;
}

.center-inner {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center space-around;
  align-items: center;
}

/** customized **/
.home-feature-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.4em;
}

.ark7 {
  .mat-drawer-content {
    display: none;
  }
}

.fullscreen-dialog-backdrop {
  background-color: white;
}

.fullscreen-dialog {
  .mat-dialog-container, .mat-mdc-dialog-container {
    box-shadow: none;
    padding: 0;
  }
}

// -----------------------------
.actionable:not(.disabled) {

  &:hover,
  &.active,
  &[color='primary'] {
    color: $primary-color;
  }
}

.text-primary {
  color: $primary-color;
}

.text-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.ark7-resource {
  h1 {
    margin-bottom: 24px;
  }

  h2 {
    margin-bottom: 12px;
  }

  h3 {
    margin-bottom: 12px;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 20px;
    margin-bottom: 12px;
  }
}

.a7-info-blocks.feature-info-blocks {
  .a7-info-block-label {
    /* Header 1 */

    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 30px;
    /* identical to box height, or 176% */

    text-align: center;
    letter-spacing: 0.8px;

    /* Adjust / Black High */

    color: #171718;
  }

  .a7-info-block-content {
    /* Body 1 */

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 170%;
    /* or 26px */

    text-align: center;
    letter-spacing: 0.24px;

    /* Adjust / Medium Emphasis */

    color: rgba(111, 120, 126, 0.8);

    max-width: 270px;
    margin: 0 auto;
  }
}


.border-box {
  box-sizing: border-box;
}
