// Hierarchy
// Parts > Size > Variables

@import './variables';

@mixin v2-obj-base($objectFit: contain) {
  display: block; //https://stackoverflow.com/questions/4630652/strange-padding-problem-around-image
  object-fit: $objectFit;
  @content;
}

$fits: contain,
cover;

@each $f in $fits {
  .v2-obj-#{$f} {
    @include v2-obj-base($objectFit: #{$f});
  }
}

$separatorDir: h,
v;
$separatorDirThickness: 1,
2,
3;

@mixin separator($separatorDir, $separatorDirThickness) {
  box-sizing: border-box;
  display: inline-block;
  //  border-radius: 50%;
  border-color: #edeef0;

  @if $separatorDir=='v' {
    border-right-width: #{$separatorDirThickness}px;
    border-right-style: solid;
    border-width: #{$separatorDirThickness}px;
  }

  @else if $separatorDir=='h' {
    border-bottom-width: #{$separatorDirThickness}px;
    border-bottom-style: solid;
    height: #{$separatorDirThickness}px;
  }
}

@each $s in $separatorDir {
  @each $t in $separatorDirThickness {
    .v2-separator-#{$s}-#{$t} {
      @include separator($s, $t);
    }
  }
}

.color-primary-green-4 {
  color: $primary-green-4;
}

@for $i from 1 through 9 {
  .color-primary-#{$i}00 {
    text-emphasis: none;
    color: map-get($primary, $i * 100);
  }

  .bg-primary-#{$i}00 {
    background: map-get($primary, $i * 100);
  }
}

.bg-primary-50 {
  background: map-get($primary, 50);
}

.bg-primary-150 {
  background: map-get($primary, 150);
}

.color-green-grey-1 {
  color: $green-grey-1;
}

.color-green-grey-2 {
  color: $green-grey-2;
}

.color-green-grey-3 {
  color: $green-grey-3;
}

.color-green-grey-4 {
  color: $green-grey-4;
}

.color-green-grey-5 {
  color: $green-grey-5;
}

.color-green-grey-6 {
  color: $green-grey-6;
}

.color-blue-grey-4 {
  color: $blue-grey-4;
}

.color-blue-grey-3 {
  color: $blue-grey-3;
}

.color-orange-1 {
  color: $orange-1;
}

.color-orange-7 {
  color: $orange-7;
}

.color-orange-8 {
  color: $orange-8;
}

.bg-orange-7 {
  background: #CC6D39;
}

.color-yellow-800 {
  color: #ad7b23;
}

.color-yellow-900 {
  color: #8f5c14;
}

.bg-green-grey-1 {
  background-color: $green-grey-1;
}

.bg-green-grey-2 {
  background-color: $green-grey-2;
}

.bg-green-grey-4 {
  background-color: $green-grey-4;
}

.bg-green-grey-6 {
  background-color: $green-grey-6;
}

.bg-green-grey-9 {
  background-color: $green-grey-9;
}

.bg-blue-grey-1 {
  background-color: $blue-grey-1;
}

.bg-blue-grey-2 {
  background-color: $blue-grey-2;
}

.bg-blue-grey-3 {
  background-color: $blue-grey-3;
}

.bg-blue-grey-4 {
  background-color: $blue-grey-4;
}

.bg-blue-grey-9 {
  background-color: $blue-grey-9;
}

.bg-subtle-blue {
  background-color: #e5e5e5;
}

.bg-warn {
  background-color: $orange-1;
}

.bg-warn-2 {
  background-color: #fffbe8;
}

.bg-warn-4 {
  background-color: $warn-4;
}

.bg-black-30 {
  background-color: $black-30;
}

.bg-black-60 {
  background-color: $black-60;
}

.bg-black-90 {
  background-color: $black-90;
}

.bg-white-80 {
  background-color: $white-80;
}

.bg-red-1 {
  background-color: $red-1;
}

.bg-red-6 {
  background-color: $red-6;
}

.border-blue-grey-4 {
  border-color: $blue-grey-4;
}

.border-blue-grey-3 {
  border-color: $blue-grey-4;
}

.border-blue-grey-2 {
  border-color: $blue-grey-2;
}

.border-blue-grey-1 {
  border-color: $blue-grey-1;
}

.border-green-grey-1 {
  border-color: $green-grey-1;
}

.border-green-grey-2 {
  border-color: $green-grey-2;
}

.border-green-grey-4 {
  border-color: $green-grey-4;
}

.border-green-grey-5 {
  border-color: $green-grey-5;
}

.border-warn-4 {
  border-color: $warn-4;
}

.color-yellow-5 {
  color: $yellow-5;
}

.color-yellow-6 {
  color: $yellow-6;
}

.bg-yellow-6 {
  background-color: $yellow-6;
}

.color-error-1 {
  color: $red-1;
}

.color-red-5 {
  color: $red-5;
}

.color-airbnb {
  color: #ff5a5f !important;
}

.svg-white {
  filter: brightness(0) invert(1);
}

.svg-primary-green-1 {
  filter: brightness(0) saturate(100%) invert(54%) sepia(69%) saturate(478%) hue-rotate(98deg) brightness(98%) contrast(93%);
}

.svg-green-grey-1 {
  filter: brightness(0) saturate(100%) invert(25%) sepia(3%) saturate(2204%) hue-rotate(103deg) brightness(95%) contrast(89%);
}

.svg-green-grey-2 {
  filter: brightness(0) saturate(100%) invert(34%) sepia(10%) saturate(290%) hue-rotate(106deg) brightness(95%) contrast(82%);
}

.svg-green-grey-3 {
  filter: brightness(0) saturate(100%) invert(62%) sepia(8%) saturate(127%) hue-rotate(104deg) brightness(93%) contrast(87%);
}

.svg-green-grey-4 {
  filter: brightness(0) saturate(100%) invert(100%) sepia(90%) saturate(15%) hue-rotate(144deg) brightness(92%) contrast(93%);
}

.svg-red-1 {
  filter: invert(42%) sepia(18%) saturate(2107%) hue-rotate(301deg) brightness(90%) contrast(93%);
}

.svg-red-5 {
  filter: brightness(0) saturate(100%) invert(80%) sepia(27%) saturate(6463%) hue-rotate(355deg) brightness(92%) contrast(87%);
}

.box-shadow-1 {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.box-shadow-2 {
  box-shadow: 0px 1px 1px 1px rgba(0, 28, 1, 0.08);
}

.box-shadow-3 {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
}

@for $i from 0 through 10 {
  $base: 0.1;

  .v2-opacity-#{$i* 10} {
    opacity: $i * $base;
  }

  .v2-scale-#{$i* 10} {
    transform: scale($i * $base);
  }
}

.debug {
  outline: 1px dotted red;
}

.border-box {
  box-sizing: border-box;
}

.scroll-y {
  overflow-y: auto scroll;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-bar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.flip-y {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.inline-block {
  display: inline-block;
}

.text-start {
  text-align: start;
}

.click-through {
  pointer-events: none;
}

.non-click-through {
  pointer-events: auto;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout-col-top {
  display: table;
  table-layout: fixed;
  width: 100%;

  >* {
    display: table-cell;
    vertical-align: top;
  }
}
