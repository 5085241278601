@use '@angular/material' as mat;
@import './theming';

@import './scss/refinement/variables';

@include exports('a7-ui-refinement') {
  @import './scss/refinement/layout';
  @import './scss/refinement/tabs';

  @include mat.core();

  .ds-refinement {
    @include a7-ui-typography($refinement-desktop-typography);
    @include a7-ui-tabs-typography($refinement-desktop-typography);

    @include a7-ui-theme($refinement-theme);
    @include a7-ui-tabs-theme($refinement-theme);

    @include a7-ui-layout();
    @include a7-ui-tabs();
  }
}
