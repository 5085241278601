@mixin a7-ui-tabs() {

  .a7-menu.mat-tab-group {
    .mat-tab-labels {
      > .mat-tab-label:not(:last-child) {
        margin-right: 24px;
      }

      .mat-tab-label {
        opacity: .3;
        padding: 0;
        min-width: 0;
        height: 36px;
        font-size: 14px;
        font-weight: 700;

        &.mat-tab-label-active {
          opacity: 1;

          .mat-tab-label-content {
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }

  .a7-sub-menu.mat-tab-group {
    .mat-tab-header {
      border-bottom-width: 0;
    }

    .mat-tab-header-pagination {
      min-width: 16px;
    }

    .mat-tab-body-wrapper {
      display: none;
    }

    .mat-tab-labels {
      > .mat-tab-label:not(:last-child) {
        margin-right: 24px;
      }

      .mat-tab-label {
        opacity: .3;
        padding: 0;
        min-width: 0;
        height: 36px;

        &.mat-tab-label-active {
          opacity: 1;

          // .mat-tab-label-content {
          //   color: rgba(0, 0, 0, 0.8);
          // }
        }
      }
    }
  }

  .mat-tab-body.mat-tab-body-active, .mat-mdc-tab-body.mat-mdc-tab-body-active {
    max-width: 100%;
  }
}

@mixin a7-ui-tabs-theme($theme) {
  $primary:    map-get($theme, primary);
  $primary-color: map-get($primary, default);

  .a7-sub-menu.mat-tab-group {

    &[color="white"] {
      .mat-tab-label {
        color: white;

        a:not(.disabled):hover {
          color: white;
        }
      }

      .mat-tab-header-pagination {

        .mat-tab-header-pagination-chevron {
          border-color: white;
        }

        &.mat-tab-header-pagination-disabled {
          opacity: 0.3;
        }
      }
    }
  }
}

@mixin a7-ui-tabs-typography($config) {
  .a7-sub-menu.mat-tab-group {
    .mat-tab-label {
      @include typography-level-to-styles($config, label);
      line-height: 36px;
    }
  }
}
