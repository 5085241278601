// Hierarchy
// Parts > Size > Variables
$stud: 12px;
$opers: margin,
padding;
$dirs: (t: top,
  b: bottom,
  l: left,
  r: right,
);

.v2-margin-2px-r {
  margin-right: 2px;
}

.v2-neg-margin-2-h {
  margin-left: -24px;
  margin-right: -24px;
}

.v2-neg-margin-10-l {
  margin-left: -120px;
}

.v2-neg-margin-14-r {
  margin-right: -168px;
}


@for $i from 0 through 64 {
  .v2-radius-#{$i}px {
    border-radius: #{$i}px;
  }

  .v2-border-#{$i}px {
    border-width: #{$i}px;
    border-style: solid;
  }

  .v2-dashed-#{$i}px {
    border-width: #{$i}px;
    border-style: dashed;
  }

  @each $d,
  $dir in $dirs {
    .v2-dashed-#{$i}px-#{$d} {
      border-#{$dir}-width: #{$i}px;
      border-#{$dir}-style: dashed;
    }

    .v2-border-#{$i}px-#{$d} {
      border-#{$dir}-width: #{$i}px;
      border-#{$dir}-style: solid;
    }
  }
}

// px units are restricted to 400px, with 4 per stud
@for $i from 0 through 100 {
  .v2-h-#{$i*4}px {
    height: $i * 4px;
  }

  .v2-w-#{$i*4}px {
    width: $i * 4px;
  }

  .v2-max-h-#{$i*4}px {
    max-height: $i * 4px;
  }

  .v2-max-w-#{$i*4}px {
    max-width: $i * 4px;
  }

  .v2-min-h-#{$i*4}px {
    min-height: $i * 4px;
  }

  .v2-min-w-#{$i*4}px {
    min-width: $i * 4px;
  }

  .v2-col-gap-#{$i*4}px {
    column-gap: $i * 4px;
  }

  .v2-row-gap-#{$i*4}px {
    row-gap: $i * 4px;
  }

  @each $d,
  $dir in $dirs {
    .v2-#{$dir}-#{$i*4}px {
      #{$dir}: $i * 4px;
    }

    @each $oper in $opers {
      .v2-#{$oper}-#{$i*4}px-#{$d} {
        #{$oper}-#{$dir}: $i * 4px;
      }

      .v2-#{$oper}-#{$i*4}px-#{$d}-n {
        #{$oper}-#{$dir}: $i * -4px;
      }

      .v2-#{$oper}-#{$i*4}px-v {
        #{$oper}-top: $i * 4px;
        #{$oper}-bottom: $i * 4px;
      }

      .v2-#{$oper}-#{$i*4}px-v-n {
        #{$oper}-top: $i * -4px;
        #{$oper}-bottom: $i * -4px;
      }

      .v2-#{$oper}-#{$i*4}px-h {
        #{$oper}-left: $i * 4px;
        #{$oper}-right: $i * 4px;
      }

      .v2-#{$oper}-#{$i*4}px-h-n {
        #{$oper}-left: $i * -4px;
        #{$oper}-right: $i * -4px;
      }

      .v2-#{$oper}-#{$i*4}px {
        #{$oper}-top: $i * 4px;
        #{$oper}-bottom: $i * 4px;
        #{$oper}-left: $i * 4px;
        #{$oper}-right: $i * 4px;
      }

      //children
      .v2-children-#{$oper}-#{$i*4}px-#{$d}>* {
        #{$oper}-#{$dir}: $i * 4px;
      }

      .v2-children-#{$oper}-#{$i*4}px-#{$d}-n>* {
        #{$oper}-#{$dir}: $i * -4px;
      }

      .v2-children-#{$oper}-#{$i*4}px-v>* {
        #{$oper}-top: $i * 4px;
        #{$oper}-bottom: $i * 4px;
      }

      .v2-children-#{$oper}-#{$i*4}px-v-n>* {
        #{$oper}-top: $i * -4px;
        #{$oper}-bottom: $i * -4px;
      }

      .v2-children-#{$oper}-#{$i*4}px-h>* {
        #{$oper}-left: $i * 4px;
        #{$oper}-right: $i * 4px;
      }

      .v2-children-#{$oper}-#{$i*4}px-h-n>* {
        #{$oper}-left: $i * -4px;
        #{$oper}-right: $i * -4px;
      }

      .v2-children-#{$oper}-#{$i*4}px>* {
        #{$oper}-top: $i * 4px;
        #{$oper}-bottom: $i * 4px;
        #{$oper}-left: $i * 4px;
        #{$oper}-right: $i * 4px;
      }
    }
  }
}

//  studs
@for $i from 0 through 120 {
  .v2-h-#{$i} {
    height: $i * $stud;
  }

  .v2-w-#{$i} {
    width: $i * $stud;
  }

  .v2-max-h-#{$i} {
    max-height: $i * $stud;
  }

  .v2-max-w-#{$i} {
    max-width: $i * $stud;
  }

  .v2-min-h-#{$i} {
    min-height: $i * $stud;
  }

  .v2-min-w-#{$i} {
    min-width: $i * $stud;
  }

  .v2-max-h-#{$i}-imp {
    max-height: $i * $stud !important;
  }

  .v2-max-w-#{$i}-imp {
    max-width: $i * $stud !important;
  }

  .v2-min-h-#{$i}-imp {
    min-height: $i * $stud !important;
  }

  .v2-min-w-#{$i}-imp {
    min-width: $i * $stud !important;
  }

  @each $d,
  $dir in $dirs {
    .v2-#{$dir}-#{$i} {
      #{$dir}: $i * $stud;
    }

    @each $oper in $opers {
      .v2-#{$oper}-#{$i}-#{$d} {
        #{$oper}-#{$dir}: $i * $stud;
      }

      .v2-#{$oper}-#{$i}-#{$d}-n {
        #{$oper}-#{$dir}: $i * -$stud;
      }

      .v2-#{$oper}-#{$i}-v {
        #{$oper}-top: $i * $stud;
        #{$oper}-bottom: $i * $stud;
      }

      .v2-#{$oper}-#{$i}-v-n {
        #{$oper}-top: $i * -$stud;
        #{$oper}-bottom: $i * -$stud;
      }

      .v2-#{$oper}-#{$i}-h {
        #{$oper}-left: $i * $stud;
        #{$oper}-right: $i * $stud;
      }

      .v2-#{$oper}-#{$i}-h-n {
        #{$oper}-left: $i * -$stud;
        #{$oper}-right: $i * -$stud;
      }

      .v2-#{$oper}-#{$i} {
        #{$oper}-top: $i * $stud;
        #{$oper}-bottom: $i * $stud;
        #{$oper}-left: $i * $stud;
        #{$oper}-right: $i * $stud;
      }

      // children
      .v2-children-#{$oper}-#{$i}-#{$d}>* {
        #{$oper}-#{$dir}: $i * $stud;
      }

      .v2-children-#{$oper}-#{$i}-#{$d}-n>* {
        #{$oper}-#{$dir}: $i * -$stud;
      }

      .v2-children-#{$oper}-#{$i}-v>* {
        #{$oper}-top: $i * $stud;
        #{$oper}-bottom: $i * $stud;
      }

      .v2-children-#{$oper}-#{$i}-v-n>* {
        #{$oper}-top: $i * -$stud;
        #{$oper}-bottom: $i * -$stud;
      }

      .v2-children-#{$oper}-#{$i}-h>* {
        #{$oper}-left: $i * $stud;
        #{$oper}-right: $i * $stud;
      }

      .v2-children-#{$oper}-#{$i}-h-n>* {
        #{$oper}-left: $i * -$stud;
        #{$oper}-right: $i * -$stud;
      }

      .v2-children-#{$oper}-#{$i}>* {
        #{$oper}-top: $i * $stud;
        #{$oper}-bottom: $i * $stud;
        #{$oper}-left: $i * $stud;
        #{$oper}-right: $i * $stud;
      }
    }
  }

  .v2-col-gap-#{$i} {
    column-gap: $i * $stud;
  }

  .v2-row-gap-#{$i} {
    row-gap: $i * $stud;
  }
}

// percentages
@for $i from 0 through 100 {
  .v2-h-#{$i}pct {
    height: $i * 1%;
  }

  .v2-w-#{$i}pct {
    width: $i * 1%;
  }

  .v2-#{$i}vw {
    width: #{$i}vw;
  }

  .v2-#{$i}vh {
    height: #{$i}vh;
  }

  .v2-max-h-#{$i}pct {
    max-height: $i * 1%;
  }

  .v2-max-w-#{$i}pct {
    max-width: $i * 1%;
  }

  .v2-min-h-#{$i}pct {
    min-height: $i * 1%;
  }

  .v2-min-w-#{$i}pct {
    min-width: $i * 1%;
  }

  .v2-max-#{$i}vw {
    max-width: #{$i}vw;
  }

  .v2-max-#{$i}vh {
    max-height: #{$i}vh;
  }

  .v2-max-h-#{$i}pct-imp {
    max-height: $i * 1% !important;
  }

  .v2-max-w-#{$i}pct-imp {
    max-width: $i * 1% !important;
  }

  .v2-min-h-#{$i}pct-imp {
    min-height: $i * 1% !important;
  }

  .v2-min-w-#{$i}pct-imp {
    min-width: $i * 1% !important;
  }

  .v2-max-#{$i}vw-imp {
    max-width: #{$i}vw !important;
  }

  .v2-max-#{$i}vh-imp {
    max-height: #{$i}vh !important;
  }

  @each $d,
  $dir in $dirs {
    .v2-#{$dir}-#{$i}pct {
      #{$dir}: $i * 1%;
    }

    .v2-#{$dir}-#{$i}pct-n {
      #{$dir}: $i * -1%;
    }
  }
}

.v2-max-w-578px {
  max-width: 578px;
}

.v2-w-200px {
  width: 200px;
}

.v2-w-64px {
  width: 64px;
}

.v2-h-64px {
  height: 64px;
}
