@mixin a7-delight-button() {
  .mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button, .mat-raised-button, .mdc-button {
    line-height: 48px;
    border-radius: 2px;
  }
}

@mixin a7-delight-button-typography($typography) {

  .mat-flat-button, .mdc-button {
    @include typography-level-to-styles($typography, button-2);
  }
}
