html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.max-width-2560 {
  max-width: 2560px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

hr {
  border-top: 0.4px solid rgba(0, 0, 0, 0.07);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.black-high {
  color: black;
}
