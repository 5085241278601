.mat-tab-group.striped-tab-group, .mat-mdc-tab-group.striped-tab-group {
  overflow: hidden;

  > .mat-tab-header, > .mat-mdc-tab-header {
    display: none;
  }

  // Fix the tab body has scroll bar.
  > .mat-tab-body-wrapper, > .mat-mdc-tab-body-wrapper {
    overflow: unset;

    > .mat-tab-body.mat-tab-body-active, > .mat-mdc-tab-body.mat-mdc-tab-body-active {
      overflow: unset;
      > .mat-tab-body-content, > .mat-mdc-tab-body-content {
        height: auto;
        overflow: unset;
      }
    }
  }
}
