/**
 * The basic stylings.
 */

html,
body,
app-root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/**
 * Text styles.
 */

.underline {
  text-decoration: underline;
}

.underline-thickness-1 {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.text-overline {
  text-decoration: overline;
}

.strikethrough {
  text-decoration: line-through;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  user-drag: none;
  -webkit-user-drag: none;
}

.alias {
  cursor: alias;
}

.all-scroll {
  cursor: all-scroll;
}

.auto {
  cursor: auto;
}

.cell {
  cursor: cell;
}

.col-resize {
  cursor: col-resize;
}

.context-menu {
  cursor: context-menu;
}

.copy {
  cursor: copy;
}

.crosshair {
  cursor: crosshair;
}

.default {
  cursor: default;
}

.e-resize {
  cursor: e-resize;
}

.ew-resize {
  cursor: ew-resize;
}

.grab {
  cursor: grab;
}

.grabbing {
  cursor: grabbing;
}

.help {
  cursor: help;
}

.move {
  cursor: move;
}

.n-resize {
  cursor: n-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nesw-resize {
  cursor: nesw-resize;
}

.ns-resize {
  cursor: ns-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.nwse-resize {
  cursor: nwse-resize;
}

.no-drop {
  cursor: no-drop;
}

.none {
  cursor: none;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.row-resize {
  cursor: row-resize;
}

.s-resize {
  cursor: s-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.text {
  cursor: text;
}

.w-resize {
  cursor: w-resize;
}

.wait {
  cursor: wait;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

a:not(.disabled),
.actionable:not(.disabled) {
  transition: color 0.2s ease 0s;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    transition: color 0.2s ease 0s;
  }
}

:focus {
  outline: none;
}

.relative {
  position: relative;
}

.hide {
  display: none !important;
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.auto-scroll {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.z-index-low {
  z-index: -1;
}

.z-index-high {
  z-index: 2;
}

.z-index-higher {
  z-index: 10;
}

.z-index-highest {
  z-index: 100;
}

.smooth {
  transition: all 0.2s ease-in-out;
}

.smoother {
  transition: all 0.6s ease-in-out;
}

// yo man
.smoothest {
  transition: all 1s ease-in-out;
}

.transparent {
  opacity: 0;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #FFF;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-last-center {
  text-align-last: center;
}

.nowrap {
  white-space: nowrap;
}

.full-width {
  width: 100%;
}

.inherit {
  width: inherit;
}

.full-height {
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.border-box {
  box-sizing: border-box;
}

.no-border {
  border: 0px;
}

.white {
  color: white;
}

.margin-auto {
  margin: auto;
}

.margin-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.center-inner-base {
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center space-around;
  align-items: center;
}

.center-inner {
  @extend .center-inner-base;
  flex-direction: row;
}

.center-inner-column {
  @extend .center-inner-base;
  flex-direction: column;
}

/**
 * Border Radius.
 */
.border-radius-2px {
  border-radius: 2px;
}

.border-radius,
.border-radius-4px {
  border-radius: 4px;
}

.border-circle {
  border-radius: 50%;
}

// enable normal scroll vertically
.swipe-trap {
  touch-action: pan-y !important;
}

.clear-padding-right {
  padding-right: 0 !important;
}

.v2-margin-n2px-t {
  margin-top: -2px;
}

.v2-margin-n4px-t {
  margin-top: -4px;
}

.v2-margin-n2-h {
  margin-left: -24px;
  margin-right: -24px;
}

.v2-margin-n16px-r {
  margin-right: -16px;
}

.max-width-100 {
  max-width: 100%;
}

.max-width-90 {
  max-width: 90%;
}

.color-light-green {
  color: #55eba2;
}

.color-green-2 {
  color: #E1FFF4;
}

.bg-blue-10 {
  background-color: #F8FDFF;
}

.bg-dark-green {
  background-color: #085F46;
}

.v2-padding-6px-h {
  padding-left: 6px;
  padding-right: 6px;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
