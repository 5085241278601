@import './theming';

@import './scss/delight/variables';

@include exports('a7-ui-delight') {
  @import './scss/delight/button';
  @import './scss/delight/spacing';
  @import './scss/delight/form-field';

  .ds-delight {
    @include a7-ui-typography($delight-desktop-typography);
    @include a7-delight-button-typography($delight-desktop-typography);

    @include a7-ui-theme($delight-theme);

    @include a7-delight-button();
  }
}
