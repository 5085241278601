@mixin a7-ui-delight-form-layout($ds: null) {
  @include with-ds($ds) {
    .a7-form-layout_content-slot-inner {
      >* {
        max-width: 100%;
      }
    }
  }

  @include with-ds($ds, '.page-SM, .page-XS') {
    .a7-form-layout_hide-header-on-small .a7-form-layout_intro-slot {
      display: none;
    }

    .a7-form-layout {
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: stretch space-between;
      align-items: stretch;

      padding-top: constant(safe-area-inset-top); //for iOS 11.2
      padding-top: env(safe-area-inset-top); //for iOS 11.1
    }

    .a7-form-layout_intro-slot {
      box-sizing: border-box;

      &:after {
        background-image: none;
      }
    }
  }

  @include with-ds($ds, '.page-XL, .page-LG, .page-MD') {
    .a7-form-layout {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: stretch space-between;
      align-items: stretch;
    }

    .a7-form-layout_intro-slot {
      width: 40%;
      flex-direction: column;
      box-sizing: border-box;
      display: flex;
      place-content: stretch space-between;
      align-items: stretch;
    }

    .a7-form-layout_content-slot {
      width: 60%;
      box-sizing: border-box;
    }

    .a7-form-layout_content-footer {
      display: none;
    }
  }

  @include with-ds($ds, '.page-XL, .page-LG') {
    .a7-form-layout_intro-slot {
      padding: 60px 60px 24px 60px;
    }

    .a7-form-layout_content-slot-inner {
      padding: 60px;
    }
  }

  @include with-ds($ds, '.page-MD') {
    .a7-form-layout_intro-slot {
      padding: 36px 36px 12px 36px;
    }

    .a7-form-layout_content-slot-inner {
      padding: 36px;
    }
  }

  @include with-ds($ds, '.page-SM') {
    .a7-form-layout_intro-slot {
      padding: 36px 60px 36px 60px;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .a7-form-layout_content-slot-inner {
      padding: 36px 60px 36px 60px;
    }
  }

  @include with-ds($ds, '.page-XS') {
    .a7-form-layout_intro-slot {
      padding: 24px 36px 24px 36px;
    }

    .a7-form-layout_content-slot-inner {
      padding: 24px 36px 24px 36px;
    }
  }
}

@include exports('a7-ui-delight-form-layout') {
  @include a7-ui-delight-form-layout();
}

@mixin a7-ui-delight-form-layout-theme($theme, $ds: null) {
  $primary: map-get($theme, primary);
  $primary-color: map-get($primary, default);

  $background: #128041;

  @include with-ds($ds, '.page-SM, .page-XS') {

    .a7-form-layout,
    &.a7-form-layout {
      background: white;
    }

    .a7-form-layout_intro-slot {
      background: white;
    }
  }

  @include with-ds($ds, '.page-XL, .page-LG, .page-MD') {

    .a7-form-layout,
    &.a7-form-layout {
      background: white;
    }

    .a7-form-layout_intro-slot {
      background: $background;
    }
  }
}

@mixin a7-ui-delight-form-layout-typography($config, $ds: null) {
  @include with-ds($ds) {

    .a7-form-layout_intro-footer,
    .a7-form-layout_content-footer {
      @include typography-level-to-styles($config, footer);
      line-height: 3em;
    }
  }
}
