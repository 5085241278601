@use '@angular/material' as mat;
@import './theming';
@import './scss/reset.scss';

@include exports('a7-ui-a2') {
  @include css-reset();

  .ds-a2 {
    // by default set all text color
    color: $green-grey-1;

    @import './scss/a2/variables';
    @import './scss/a2/size';
    @import './scss/a2/parts';

    @include mat.core();
    @include a7-ui-typography($a2-desktop-typography, $a2-homepage-typography, $a2-mobile-typography);
    @include a7-ui-theme($a2-theme);

    @import './scss/a2/ng_deep';

    .new-badge::after {
      content: "NEW";
      font-family: "Heebo";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: white;
      background-color: #1EC373;
      padding: 1px 6px;
      text-align: center;
      border-radius: 3px;
      display: inline-block;
      transform: translate(2px, -8px);
    }

  }
}
