@use '@angular/material' as mat;

@import '../a2/variables';
$dirs: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);
$sizes: (
  1: 1px,
  2: 2px,
);
$a7-color-hover-overlay: rgba(0, 0, 0, 0.08);
$a7-color-pressed-overlay: rgba(0, 0, 0, 0.16);
$a7-color-black-disabled: rgba(0, 0, 0, 0.38);
$a7-color-black-inactive: rgba(0, 0, 0, 0.54);
$a7-color-black-medium-high-emphasis: rgba(0, 0, 0, 0.8);
$a7-color-black-medium-emphasis: rgba(0, 0, 0, 0.6);
$a7-color-white-medium-emphasis: rgba(255, 255, 255, 0.7);
$a7-color-white-disabled: rgba(255, 255, 255, 0.5);
$a7-color-white-inactive: rgba(255, 255, 255, 0.54);
$a7-color-separator-default: rgba(0, 0, 0, 0.18);
$a7-color-white-separator: rgba(255, 255, 255, 0.18);

@mixin a7-ui-base-theme($theme) {
  $a7-color-separator: if(
    map-has-key($theme, color-separator),
    map-get($theme, color-separator),
    $a7-color-separator-default
  );

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $primary-color: map-get($primary, 500);
  $accent-color: map-get($accent, 500);
  $warn-color: map-get($warn, 500);

  .text-primary {
    color: $primary-color;
  }

  .text-accent {
    color: $accent-color;
  }

  .text-warn {
    color: $warn-color;
  }

  .background-app-ui {
    background: #ffffff;
  }

  .background-disabled {
    background: $a7-color-black-disabled;
  }

  .background-orange {
    background: #f08b16;
  }

  .background-blue-2 {
    background: #2d9cdb;
  }

  .background-purple-1 {
    background: #9b51e0;
  }

  .background-primary {
    background: $primary-color;
  }

  .background-accent {
    background: $accent-color;
  }

  .background-warn {
    background: $warn-color;
  }

  .background-hover-overlay {
    background: $a7-color-hover-overlay;
  }

  .background-black-medium-high-emphasis {
    background: $a7-color-black-medium-high-emphasis;
  }

  .text-disabled,
  .text-black-disabled {
    color: $a7-color-black-disabled;
  }

  .text-inactive,
  .text-black-inactive {
    color: $a7-color-black-disabled;
  }

  .text-black-high-emphasis {
    color: #000000;
  }

  .text-black-medium-high-emphasis {
    color: $a7-color-black-medium-high-emphasis;
  }

  .text-black-medium-emphasis {
    color: $a7-color-black-medium-emphasis;
  }

  .text-black-focus-overlay {
    color: rgba(0, 0, 0, 0.12);
  }

  .text-white-high-emphasis {
    color: #ffffff;
  }

  .text-white-medium-emphasis {
    color: $a7-color-white-medium-emphasis;
  }

  .text-white-disabled {
    color: $a7-color-white-disabled;
  }

  .text-white-inactive {
    color: $a7-color-white-inactive;
  }

  .border-white {
    border-color: #ffffff;
  }

  .border-primary {
    border-color: $primary-color;
  }

  .border-disabled {
    border-color: $a7-color-black-disabled;
  }

  .border-accent {
    border-color: $accent-color;
  }

  .border-warn {
    border-color: $warn-color;
  }

  .actionable.disabled {
    opacity: 0.38;
  }

  .border-width-1 {
    border-width: 1px;
  }

  .border-solid {
    border-style: solid;
  }

  .border-transparent {
    border-color: rgba(0, 0, 0, 0);
  }

  .border {
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  .no-max-width {
    max-width: none !important;
  }

  a {
    color: inherit;
  }

  a:not(.disabled):not(.flat-button):not(.stroke-button),
  .actionable:not(.disabled):not(.flat-button):not(.stroke-button) {
    transition: color 0.2s ease-in-out;

    &:hover,
    &.active,
    &[color='primary'] {
      color: $primary-color;
    }

    &[color='warn'] {
      color: lighten($warn-color, 10%);
    }

    &[color='warn']:hover,
    &[color='warn'].active {
      color: $warn-color;
    }

    &[color='accent'] {
      color: lighten($accent-color, 10%);
    }

    &[color='accent']:hover,
    &[color='accent'].active {
      color: $accent-color;
    }

    &[color='black-inactive'] {
      color: $a7-color-black-inactive;

      &:hover,
      &.active {
        color: $a7-color-black-medium-high-emphasis;
      }
    }

    &[color='black-medium-high'] {
      color: $a7-color-black-medium-high-emphasis;

      &:hover,
      &.active {
        color: black;
      }
    }

    &[color='black-medium'] {
      color: $a7-color-black-medium-emphasis;

      &:hover,
      &.active {
        color: black;
      }
    }

    &[color='green-grey-2'] {
      color: $green-grey-2;

      &:hover,
      &.active {
        color: $green-grey-1;
      }
    }

    &[color='green-grey-3'] {
      color: $green-grey-3;

      &:hover,
      &.active {
        color: $green-grey-1;
      }
    }

    &[color='white'] {
      color: white;

      &:hover,
      &.active {
        color: white;
      }
    }

    &[color='white-medium'] {
      color: rgba(255, 255, 255, 0.7);

      &:hover,
      &.active {
        color: white;
      }
    }
  }

  @each $scale, $size in $sizes {
    .separator-#{$scale} {
      border-color: $a7-color-separator;
    }

    .border-#{$scale},
    .border-#{$scale}-h,
    .border-#{$scale}-v {
      border-color: $a7-color-separator;
    }

    @each $d, $dir in $dirs {
      .border-#{$scale}-#{$d} {
        border-color: $a7-color-separator;
      }
    }

    @each $d, $dir in $dirs {
      .separator-#{$scale}-#{$d} {
        border-color: $a7-color-separator;
      }

      .separator-#{$scale}-#{$d}-primary {
        border-color: $primary-color;
      }

      .separator-#{$scale}-#{$d}-warn {
        border-color: $warn-color;
      }

      .separator-#{$scale}-#{$d}-hover-primary {
        &:hover {
          border-color: $primary-color;
        }
      }
    }
  }

  .separator-primary {
    border-color: $primary-color;
  }

  .border-separator {
    border: 1px solid $a7-color-separator;
  }

  .border-white-separator {
    border-color: $a7-color-white-separator;
  }

  /* Material overrides */
  .mat-button-toggle-button {
    &:after {
      background: $primary-color;
    }
  }

  .mat-button-toggle-checked {
    .mat-button-toggle-label-content {
      color: $primary-color;
    }
  }

  .text-50 {
    color: map-get($primary, 50);
  }

  .border-50 {
    border-color: map-get($primary, 50);
  }

  .bg-transparent {
    background-color: transparent;
  }

  .edge-fade:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      white 0 1%,
      rgba(255, 0, 0, 0) 5% 95%,
      white 99% 100%
    );
    width: 100%;
    height: 100%;
  }

  .bg-white {
    background-color: white;
  }

  // below does not work with opacity
  $blur-value: 10px; // You can adjust this value as needed
  @for $i from 0 through 10 {
    $opacity: $i * 0.1;
    $name: $i * 10 + 'pct';
    .bg-blur-black-#{$name} {
      background-color: rgba(0, 0, 0, $opacity);
      backdrop-filter: blur($blur-value);
    }
  }

  @for $i from 1 through 9 {
    $hundreds: $i * 100;

    .text-#{$hundreds} {
      color: map-get($primary, $i * 100);
    }

    .text-warn-#{$hundreds} {
      color: map-get($warn, $i * 100);
    }

    .font-weight-#{$hundreds} {
      font-variation-settings: 'wght' $i * 90;
    }

    .border-#{$hundreds} {
      border-color: map-get($primary, $i * 100);
    }

    .bg-#{$hundreds} {
      background-color: map-get($primary, $i * 100);
    }
  }

  .bg-50 {
    background-color: map-get($primary, 50);
  }

  @keyframes tooltip-slide {
    0% {
      opacity: 0;
      transform: translate(0%, -30%);
    }

    100% {
      opacity: 1;
      transform: translate(0%, 0);
    }
  }

  .tooltip-container {
    text-align: start;
    z-index: 1001;
    position: fixed;
    font-weight: 600;
    max-width: min(60%, 400px);
    background: rgba(20, 31, 26, 0.8);
    border-radius: 8px;
    box-sizing: border-box;
    opacity: 0;
    transform: translate(0%, -30%);
    animation: tooltip-slide 0.18s ease-out 0.5s;
    animation-fill-mode: forwards;
    // pointer-events: none;
    color: #fff;
    padding: 8px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    font: 400 14px/21px Heebo;
    letter-spacing: 0;
  }
}

@mixin a7-ui-theme($theme) {
  @include mat.core-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.button-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.input-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.slide-toggle-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.table-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.badge-theme($theme);
  // @include mat.bottom-sheet-theme($theme);
  @include mat.card-theme($theme);
  // @include mat.expansion-panel-theme($theme);
  @include mat.grid-list-theme($theme);
  // @include mat.list-theme($theme);
  // @include mat.paginator-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.stepper-theme($theme);
  // @include mat.toolbar-theme($theme);
  // @include mat.tree-theme($theme);
  @include a7-ui-base-theme($theme);
}
