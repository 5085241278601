@use '@angular/material'as mat;
// Hierarchy

// common colors
$black-30: rgba(20, 31, 26, 0.3);
$black-60: rgba(20, 31, 26, 0.6);
$black-90: rgba(20, 31, 26, 0.9);
$white-80: rgba(255, 255, 255, 0.8);

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$primary-green-4: #0f845a;

$green-grey-1: #3b4742;
$green-grey-2: #565e5b;
$green-grey-3: #8f9492;
$green-grey-4: #e1e3e2;
$green-grey-5: #f0f0f0;
$green-grey-6: #737a77;
$green-grey-9: #232E29;


$blue-grey-1: #f9fbfc;
$blue-grey-2: #edeef0;
$blue-grey-3: #dee1e3;
$blue-grey-4: #c1c4c7;
$blue-grey-9: #25292e;


$orange-1: #ffb066;
$orange-2: #FFE4D1;
$orange-3: #FFD2B2;
$orange-4: #FFBE8A;
$orange-5: #FFF7F0;
$orange-6: #EB9050;
$orange-7: #CC6D39;
$orange-8: #A14D2A;
$orange-9: #7A3118;
$orange-5: #FFF7F0;


$red-1: #cf495d;
$red-5: #f63845; //red-B500
$red-6: #c73841; //red-600


$yellow-5: #ffe35c;
$yellow-6: #f5ce4e;


$warn-4: #ffe980; //yellow


// Prepare the typography.
$a2-desktop-typography: typography-config($font-family: 'Heebo',
  $display-2: typography-level(48px, 64px, 700, null, 0),
  $display-1: typography-level(40px, 54px, 700, null, 0),
  $headline: typography-level(32px, 44px, 700, null, 0), // h1
  $title: typography-level(24px, 32px, 700, null, 0), // h2
  $subheading-2: typography-level(20px, 30px, 700, null, 0), //h3
  $subheading-1: typography-level(18px, 32px, 500, null, 0), //h4
  $h5: typography-level(16px, 28px, 500, null, 0),
  $subtitle-1: typography-level(20px, 32px, 500, null, 0),
  $subtitle-2: typography-level(16px, 24px, 500, null, 0),
  $subtitle-3: typography-level(18px, 27px, 500, null, 0),
  $body-1: typography-level(18px, 32px, 400, null, 0),
  $body-1-bold: typography-level(18px, 32px, 500, null, 0),
  $body-2: typography-level(16px, 28px, 400, null, 0),
  $body-2-bold: typography-level(16px, 28px, 500, null, 0),
  $label-1: typography-level(14px, 25px, 500, null, 0),
  $label-2: typography-level(14px, 25px, 400, null, 0),
  $footer: typography-level(12px, 16px, 400, null, 0),
  $footer-1: typography-level(12px, 21px, 700, null, 0),
  $footer-2: typography-level(12px, 21px, 400, null, 0),
  $footer-3: typography-level(10px, 18px, 700, null, 0),
  $footer-4: typography-level(10px, 18px, 400, null, 0),
);


$a2-homepage-typography: typography-config($font-family: 'Heebo',


  $display-2: typography-level(64px, 86px, 700, null, 0),
  $display-1: typography-level(48px, 65px, 700, null, 0),
  $headline: typography-level(40px, 54px, 700, null, 0), // h1
  $title: typography-level(32px, 43px, 700, null, 0), // h2
  $subheading-2: typography-level(24px, 36px, 700, null, 0), // h3
  $subheading-1: typography-level(20px, 35px, 500, null, 0), // h4
  $h5: typography-level(18px, 32px, 500, null, 0),


  $body-1: typography-level(20px, 35px, 400, null, 0),
  $body-1-bold: typography-level(20px, 35px, 700, null, 0),
  $body-2: typography-level(18px, 32px, 400, null, 0),
  $body-2-bold: typography-level(18px, 32px, 700, null, 0),
  $label-1: typography-level(16px, 28px, 500, null, 0),
  $label-2: typography-level(14px, 21px, 400, null, 0),
  $caption: typography-level(16px, 28px, 400, null, 0px),
  $footer-1: typography-level(14px, 25px, 700, null, 0),
  $footer-2: typography-level(12px, 25px, 400, null, 0),
);


$a2-mobile-typography: typography-config($font-family: 'Heebo',
  $display-2: typography-level(36px, 48px, 700, null, 0),
  $display-1: typography-level(32px, 44px, 700, null, 0),
  $headline: typography-level(24px, 32px, 700, null, 0), // h1
  $title: typography-level(20px, 30px, 700, null, 0), // h2
  $subheading-2: typography-level(18px, 28px, 700, null, 0), // h3
  $subheading-1: typography-level(16px, 28px, 500, null, 0), // h4
  $h5: typography-level(14px, 25px, 500, null, 0),
  $body-1: typography-level(16px, 28px, 400, null, 0),
  $body-1-bold: typography-level(16px, 28px, 700, null, 0),
  $body-2: typography-level(14px, 25px, 400, null, 0),
  $body-2-bold: typography-level(14px, 25px, 700, null, 0),
  $label-1: typography-level(12px, 21px, 500, null, 0),
  $label-2: typography-level(12px, 21px, 400, null, 0),
  $footer-1: typography-level(10px, 18px, 700, null, 0),
  $footer-2: typography-level(10px, 18px, 400, null, 0),
);


// Prepare the color theme.
$primary: ( //
  50: #f0fffa,
  100: #e1fff4,
  200: #aff0d5,
  300: #7be0b1,
  400: #50d494,
  500: #1ec373,
  600: #16a86b,
  700: #0f855a,
  800: #096148,
  900: #014738,
  A50: #edfcf7,
  A100: #b9f6ca,
  A200: #69f0ae,
  A300: #99e0c2,
  A400: #00e676,
  A600: #42a67c,
  A700: #338f69,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A300: $dark-primary-text,
    A400: $dark-primary-text,
    A600: $dark-primary-text,
    A700: $dark-primary-text,
  ));
$accent: (50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ));
$warn: (50: #fbe2c6,
  100: #fff7f0,
  200: #ffe4d1,
  300: #ffd2b2,
  400: #ffbe8a,
  500: #eb9050,
  600: #d6790e,
  700: #cc6d39,
  800: #9e4620,
  900: #7a2a0f,
  A100: #fff7f0,
  A200: #ffe4d1,
  A400: #ffbe8a,
  A500: #f0a977,
  A600: #db8f58,
  A700: #bb794a,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A500: $dark-primary-text,
    A600: $dark-primary-text,
    A700: $dark-primary-text,
  ));


$red: (100: #fcf2f3,
  200: #f5c4ca,
  300: #eb919c,
  400: #e36875,
  500: #cf495d,
  600: #c73841,
  700: #b22e35,
  800: #9e262a,
  900: #8a1e20,
  A400: #dc5c6f,
  A500: #b6425b,
  B500: #f63845,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A400: $dark-primary-text,
    A500: $dark-primary-text,
    B500: $dark-primary-text,
  ));


$yellow: (100: #fffbe8,
  150: #fff7d0,
  200: #fff4bd,
  300: #ffef9e,
  400: #ffe980,
  500: #ffe35c,
  600: #f5ce4e,
  700: #dcac3e,
  800: #ad7b23,
  900: #8f5c14,
  A300: #f9e898,
  A400: #f5db83,
  A500: #e7ca6d,
  A900: #7c6742,
  contrast: (100: $dark-primary-text,
    150: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A400: $dark-primary-text,
    A500: $dark-primary-text,
    A900: $dark-primary-text,
  ));


$green-grey: (100: #fafafa,
  150: #f0f0f0,
  200: #e1e3e2,
  300: #c7c9c9,
  400: #aaadac,
  500: #8f9492,
  600: #737a77,
  700: #565e5b,
  800: #3b4742,
  900: #232e29,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ));


$blue-grey: (100: #f9fbfc,
  150: #edeff0,
  200: #dee1e3,
  300: #c1c4c7,
  400: #a6aaad,
  500: #8d9094,
  600: #71767a,
  700: #5c6166,
  800: #42474d,
  900: #25292e,
  contrast: (100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ));


$primary-palette: mat.define-palette($primary);
$accent-palette: mat.define-palette($accent);
$warn-palette: mat.define-palette($warn);


$ark7-theme: mat.define-light-theme((color: (primary: $primary-palette, accent: $accent-palette, warn: $warn-palette)));
$foreground: map-merge(mat.$light-theme-foreground-palette,
  (divider: rgba(black, 0.04),
    dividers: rgba(black, 0.12),
    text: $green-grey-1,
  ));
$background: map-merge(mat.$light-theme-background-palette,
  (background: white,
    card: white,
    // mat-color($mat-gray, 50),
  ));


$color-separator: #e1e3e27f;


$a2-theme: map-merge($ark7-theme,
  (background: $background,
    foreground: $foreground,
    color-separator: $color-separator,
  ));
