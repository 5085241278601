// Separators
$opers: margin, padding;
$dirs: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);

@mixin ds-delight-spacing-scale($scale, $size, $screen) {
  @each $oper in $opers {

    .children-#{$oper}-#{$scale}-v {
      > *:not(:first-child) {
        #{$oper}-top: $size;
      }
    }

    .#{$oper}-#{$scale} {
      #{$oper}: $size;
    }

    .#{$oper}-#{$scale}-n {
      #{$oper}: 0 - $size;
    }

    .#{$oper}-#{$scale}-h {
      #{$oper}-left:   $size;
      #{$oper}-right:  $size;
    }

    .#{$oper}-#{$scale}-h-n {
      #{$oper}-left:   0 - $size;
      #{$oper}-right:  0 - $size;
    }

    .#{$oper}-#{$scale}-v {
      #{$oper}-top:     $size;
      #{$oper}-bottom:  $size;
    }

    .#{$oper}-#{$scale}-v-n {
      #{$oper}-top:     0 - $size;
      #{$oper}-bottom:  0 - $size;
    }

    @each $d, $dir in $dirs {
      .#{$oper}-#{$scale}-#{$d} {
        #{$oper}-#{$dir}:     $size;
      }

      .#{$oper}-#{$scale}-#{$d}-n {
        #{$oper}-#{$dir}:     0 - $size;
      }
    }

    .#{$oper}-#{$scale}_#{$screen} {
      #{$oper}: $size !important;
    }

    .#{$oper}-#{$scale}-n_#{$screen} {
      #{$oper}: 0 - $size !important;
    }

    .#{$oper}-#{$scale}-h_#{$screen} {
      #{$oper}-left:   $size !important;
      #{$oper}-right:  $size !important;
    }

    .#{$oper}-#{$scale}-h-n_#{$screen} {
      #{$oper}-left:   0 - $size !important;
      #{$oper}-right:  0 - $size !important;
    }

    .#{$oper}-#{$scale}-v_#{$screen} {
      #{$oper}-top:     $size !important;
      #{$oper}-bottom:  $size !important;
    }

    .#{$oper}-#{$scale}-v-n_#{$screen} {
      #{$oper}-top:     0 - $size !important;
      #{$oper}-bottom:  0 - $size !important;
    }

    @each $d, $dir in $dirs {
      .#{$oper}-#{$scale}-#{$d}_#{$screen} {
        #{$oper}-#{$dir}:     $size !important;
      }

      .#{$oper}-#{$scale}-#{$d}-n_#{$screen} {
        #{$oper}-#{$dir}:     0 - $size !important;
      }
    }
  }
}

@mixin ds-delight-spacing($lg, $md, $sm, $screen) {
  @include ds-delight-spacing-scale(lg, $lg, $screen);
  @include ds-delight-spacing-scale(md, $md, $screen);
  @include ds-delight-spacing-scale(sm, $sm, $screen);
}

.ds-delight {

  @media only screen and (min-width: 0) and (max-width: 599px) {
    @include ds-delight-spacing(60px, 24px, 12px, xs);
  }

  @media only screen and (min-width: 600px) and (max-width: 1023px) {
    @include ds-delight-spacing(100px, 60px, 24px, sm);
  }

  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    @include ds-delight-spacing(60px, 36px, 24px, md);
  }

  @media only screen and (min-width: 1440px) {
    @include ds-delight-spacing(100px, 60px, 36px, lg);
  }
}
