$spacer: 36px;
$spacer-sm: 16px;
$spacer-md: 24px;
$spacer-lg: 36px;

@function space-content($sp, $mul1, $mul2: null, $mul3: null, $mul4: null) {
  @if $mul2 and $mul3 and $mul4 {
    @return $sp * $mul1 $sp * $mul2 $sp * $mul3;
  }
  @else if $mul2 and $mul3 {
    @return $sp * $mul1 $sp * $mul2 $sp * $mul3;
  }
  @else if $mul2 {
    @return $sp * $mul1 $sp * $mul2;
  }
  @else {
    @return $sp * $mul1;
  }
}

@mixin spacing($target, $mul1, $mul2: null, $mul3: null, $mul4: null) {

  #{$target}: space-content($spacer, $mul1, $mul2, $mul3, $mul4);

  @media only screen and (min-width: 0) and (max-width: 719px) {
    #{$target}: space-content($spacer-sm, $mul1, $mul2, $mul3, $mul4);
  }

  @media only screen and (min-width: 720) and (max-width: 1023px) {
    #{$target}: space-content($spacer-md, $mul1, $mul2, $mul3, $mul4);
  }

  @media only screen and (min-width: 1024px) {
    #{$target}: space-content($spacer-lg, $mul1, $mul2, $mul3, $mul4);
  }
}

@mixin column($total, $column) {
  margin-right: $spacer;
  width: calc(100% / #{$total} * #{$column} - #{$spacer});
  max-width: calc(100% - #{$spacer});

  @media only screen and (min-width: 0) and (max-width: 719px) {
    margin-right: $spacer-sm;
    width: calc(100% / #{$total} * #{$column} - #{$spacer-sm});
    max-width: calc(100% - #{$spacer-sm});
  }

  @media only screen and (min-width: 720) and (max-width: 1023px) {
    margin-right: $spacer-md;
    width: calc(100% / #{$total} * #{$column} - #{$spacer-md});
    max-width: calc(100% - #{$spacer-md});
  }

  @media only screen and (min-width: 1024px) {
    margin-right: $spacer-lg;
    width: calc(100% / #{$total} * #{$column} - #{$spacer-lg});
    max-width: calc(100% - #{$spacer-lg});
  }
}

@function vh($x) {
  @return var(--vh, 1vh) * $x;
}

@mixin minVH($offset: 0px, $height: 100) {
  min-height: calc(#{$height}vh + #{$offset});
  min-height: calc(var(--vh, 1vh) * #{$height} + #{$offset});
}

@mixin VH($offset: 0px, $height: 100) {
  height: calc(#{$height}vh + #{$offset});
  height: calc(var(--vh, 1vh) * #{$height} + #{$offset});
}
