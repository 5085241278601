@use '@angular/material'as mat;
@import './typography-utils';

// Represents a typography level from the Material design spec.
@function typography-level($font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: null) {

  @return (font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight,
    font-family: $font-family,
    letter-spacing: $letter-spacing);
}

// Represents a collection of typography levels.
@function typography-config($font-family: 'LatoLatin, sans-serif',
  $display-4: typography-level(96px, 120px, 400, null, 0.8px),
  $display-3: typography-level(64px, 84px, 400, null, 0.8px),
  $display-2: typography-level(48px, 60px, 400, null, 0.8px),
  $display-1: typography-level(36px, 48px, 400, null, 0.8px),
  $headline: typography-level(24px, 36px, 400, null, 0.8px),
  $title: typography-level(20px, 36px, 500, null, 0.74px),
  $subheading-2: typography-level(16px, 24px, 400, null, 0.87px),
  $subheading-1: typography-level(14px, 24px, 500, null, 0.58px),
  $h5: typography-level(14px, 24px, 500, null, 0.58px),

  $subtitle-1: typography-level(14px, 24px, 500, null, 0.58px),
  $subtitle-2: typography-level(16px, 24px, 400, null, 0.87px),
  $subtitle-3: typography-level(18px, 24px, 400, null, 1.06px),

  $body-1: typography-level(15px, 28px, 300, null, 0.23px),
  $body-1-bold: typography-level(15px, 28px, 700, null, 0.23px),
  $body-2: typography-level(15px, 28px, 400, null, 0.29px),
  $body-2-bold: typography-level(15px, 28px, 700, null, 0.29px),


  $overline: typography-level(14px, 24px, 400, null, 0px),
  $caption: typography-level(14px, 24px, 400, null, 0px),
  $label: typography-level(13px, 20px, 500, null, 0.15px),
  $label-1: typography-level(16px, 24px, 400, null, 0.15px),
  $label-2: typography-level(16px, 24px, 400, null, 0.15px),
  $label-content: typography-level(13px, 20px, 300, null, 0.15px),

  $footer: typography-level(11px, 11px, 200, null, 0.25px),
  $footer-1: typography-level(11px, 11px, 200, null, 0.25px),
  $footer-2: typography-level(11px, 11px, 200, null, 0.25px),
  $footer-3: typography-level(11px, 11px, 200, null, 0.25px),
  $footer-4: typography-level(11px, 11px, 200, null, 0.25px),
  $button: typography-level(16px, 24px, 400, null, 0px),
  $button-2: typography-level(16px, 24px, 400, null, 0px),
  $button-3: typography-level(16px, 24px, 300, null, 0px),
  // Line-height must be unit-less fraction of the font-size.
  $input: typography-level(13px, 1.5384615385, 300, null, 0.25px),
) {

  // Declare an initial map with all of the levels.
  $config: (display-4: $display-4,
      display-3: $display-3,
      display-2: $display-2,
      display-1: $display-1,
      headline: $headline,
      title: $title,
      subheading-2: $subheading-2,
      subheading-1: $subheading-1,
      h5: $h5,

      subtitle-3: $subtitle-3,
      subtitle-2: $subtitle-2,
      subtitle-1: $subtitle-1,

      body-1: $body-1,
      body-1-bold: $body-1-bold,
      body-2: $body-2,
      body-2-bold: $body-2-bold,
      caption: $caption,
      overline: $overline,
      label: $label,
      label-1: $label-1,
      label-2: $label-2,
      label-content: $label-content,
      footer: $footer,
      footer-1: $footer-1,
      footer-2: $footer-2,
      footer-3: $footer-3,
      footer-4: $footer-4,
      button: $button,
      button-2: $button-2,
      button-3: $button-3,
      input: $input,
    );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key,
  $level in $config {
    @if map-get($level, font-family)==null {
      $new-level: map-merge($level, (font-family: $font-family));
      $config: map-merge($config, ($key: $new-level));
    }
  }

  // Add the base font family to the config.
  @return map-merge($config, (font-family: $font-family));
}

@mixin base-typography($config) {

  .display-4 {
    @include typography-level-to-styles($config, display-4);
  }

  .display-3 {
    @include typography-level-to-styles($config, display-3);
  }

  .display-2 {
    @include typography-level-to-styles($config, display-2);
  }

  .display-1 {
    @include typography-level-to-styles($config, display-1);
  }

  h1,
  .h1,
  .headline {
    @include typography-level-to-styles($config, headline);
  }

  h2,
  .h2,
  .title,
  .section-title {
    @include typography-level-to-styles($config, title);
  }

  h3,
  .h3,
  .subheading-2,
  .site-menu {
    @include typography-level-to-styles($config, subheading-2);
  }

  h4,
  .h4,
  .subheading-1,
  .panel-heading-1 {
    @include typography-level-to-styles($config, subheading-1);
  }

  h5,
  .h5 {
    @include typography-level-to-styles($config, h5);
  }

  // h6, .h6 {
  // @include typography-level-to-styles($config, h6);
  // }

  .button-2 {
    @include typography-level-to-styles($config, button-2);
  }

  .button-3 {
    @include typography-level-to-styles($config, button-3);
  }

  .subtitle-1,
  strong {
    @include typography-level-to-styles($config, subtitle-1);
  }

  .subtitle-2 {
    @include typography-level-to-styles($config, subtitle-2);
  }


  .subtitle-3 {
    @include typography-level-to-styles($config, subtitle-3);
  }

  .overline {
    @include typography-level-to-styles($config, overline);
  }

  .label,
  label {
    @include typography-level-to-styles($config, label);
  }


  .label-1 {
    @include typography-level-to-styles($config, label-1);
  }


  .label-2 {
    @include typography-level-to-styles($config, label-2);
  }

  .label-content {
    @include typography-level-to-styles($config, label-content);
  }

  .body-2 {
    @include typography-level-to-styles($config, body-2);
  }

  .body-1-bold {
    @include typography-level-to-styles($config, body-1-bold);
  }

  .body-2-bold {
    @include typography-level-to-styles($config, body-2-bold);
  }

  @include typography-level-to-styles($config, body-1);

  p {
    margin: 0 0 12px;
  }

  .body-1 {
    @include typography-level-to-styles($config, body-1);

    p {
      margin: 0 0 12px;
    }
  }

  .input {
    @include typography-level-to-styles($config, input);
  }

  .small,
  .caption {
    @include typography-level-to-styles($config, caption);
  }

  .button {
    @include typography-level-to-styles($config, button);
  }

  .footer {
    @include typography-level-to-styles($config, footer);
  }


  .footer-1 {
    @include typography-level-to-styles($config, footer-1);
  }


  .footer-2 {
    @include typography-level-to-styles($config, footer-2);
  }

  .footer-3 {
    @include typography-level-to-styles($config, footer-3);
  }

  .footer-4 {
    @include typography-level-to-styles($config, footer-4);
  }
}

@mixin a7-ui-typography($typography, $homepage:null, $mobile:null) {
  @include base-typography($typography);

  @if ($homepage) {

    .homepage-typography,
    &.homepage-typography {
      @include base-typography($homepage);
    }
  }

  @if ($mobile) {

    .mobile-typography,
    &.mobile-typography {
      @include base-typography($mobile);
    }
  }

  @include mat.core();
  // @include mat-core($typography);
}
