.mat-radio-outer-circle {
  border: 1px solid #c1c4c7;
}

.mat-radio-button.mat-radio-disabled {
  .mat-radio-outer-circle {
    border: 0px;
  }
}

.mat-checkbox-frame,
.mat-checkbox-background,
.mdc-checkbox__background
.mat-checkbox-anim-unchecked-checked .mat-checkbox-anim-checked-unchecked {
  transform: scale(1.2);
  border: 1px solid #c1c4c7;
}

.mat-checkbox-inner-container {
  margin-right: 12px;
}

.mat-checkbox-background, .mdc-checkbox__background {
  background: none !important;
}

.mat-checkbox-checked, .mat-mdc-checkbox-checked {
  .mat-checkbox-background, .mdc-checkbox__background {
    border-color: #10ab53;
  }

  .mat-checkbox-checkmark-path, .mdc-checkbox__checkmark-path {
    stroke: #10ab53 !important;
  }
}

.mat-dialog-container, .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  border-radius: 8px;
  color: $green-grey-1;
}
.mat-mdc-dialog-container:has(.dialog-padding) .mat-mdc-dialog-surface {
  padding: 24px;
}
.mat-mdc-dialog-container:has(.dialog-padding-no-top) .mat-mdc-dialog-surface {
  padding: 0 24px 24px;
}
.mat-mdc-dialog-title {
  margin-bottom: 20px;
  padding: 0;
}
.mdc-dialog__title::before {
  display: none;
}
.mdc-button.mat-primary:not(.mat-mdc-outlined-button) {
  color: #fff;
  font: 400 16px/24px Heebo;
}
.mdc-button {
  min-height: 48px !important;
}
.mdc-button.primary:not(:disabled) {
  background: #1ec373 !important;
  color: #fff;
}
.mat-mdc-form-field-error {
  font: 400 12px/18px Heebo;
}

.v2-themed-inputs {
  .mat-dialog-container, .mat-mdc-dialog-container {
    border-radius: 8px;
  }

  .mat-form-field-underline, .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    background: none;

    .mat-mdc-form-field-focus-overlay {
      background: none;
    }
  }

  .mat-form-field, .mat-mdc-form-field {
    height: 48px;
    background-color: #f9fbfc;
    border-radius: 8px;
    padding: 0 20px;

    .mat-form-field-infix, .mat-mdc-form-field-infix {
      padding: 0;
      border-top: 0 !important;
      min-height: auto !important;
    }

    &.mat-form-field-invalid, &.mat-mdc-form-field-invalid {
      border: 1px solid #cf495d;

      .mat-form-field-infix, .mat-mdc-form-field-infix {
        border-top: 0.84375em solid transparent;
      }
    }

    .mat-form-field-label, .mat-mdc-form-field-label {
      color: #c7c9c9;
    }
  }

  .input-filled-style .mat-mdc-form-field {
    height: auto !important;
    padding: 0;
    font: 400 14px/21px Heebo;

    .mat-mdc-form-field-infix {
      padding: 14px 0 13px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      margin-top: -22px;
    }
  }
}
.sb-circles-light.sb-group {
  .sb-wrapper {
    .sb-icon {
      box-shadow: none;
      background: none;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: block;
      width: 32px;
      height: 32px;
    
      // set original icon sbg as transparent
      svg path {
        opacity: 0;
      }
    }
  }
  .sb-email .sb-icon {
    background-image: url('/assets/email.svg');
  }
  
  .sb-whatsapp .sb-icon {
    background-image: url('/assets/whatsapp.svg');
  }
  
  .sb-twitter .sb-icon {
    background-image: url('/assets/twitter.svg');
  }
  
  .sb-facebook .sb-icon {
    background-image: url('/assets/facebook.svg');
  }
  
  .sb-copy .sb-icon {
    background-image: url('/assets/copyLink.svg');
  }
}
